import React from 'react';
import {FieldValues} from 'react-hook-form';

import {AbstractFormItem} from '../Base/AbstractFormItem';
import {getTextInputClasses, TextInputProps} from '../Base/Models/TextInput';

export function FormTextInput<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  type = 'text',
  placeholder,
  bgColor = 'white',
  inputClasses,
  ariaLabel,
  onBlur: _onBlur,
  onChange: _onChange,
  readOnly,
  disabled,
  ...rest
}: TextInputProps<TFieldValues, TContext>) {
  return (
    <AbstractFormItem
      id={id}
      {...rest}
      render={({
        field: {value, onChange, onBlur, name},
        fieldState: {error},
      }) => (
        <input
          readOnly={readOnly}
          name={name}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          type={type}
          value={rest.overrideValue ?? value ?? rest.value}
          required={rest.isRequired}
          defaultValue={rest.defaultValue}
          onChange={e => {
            onChange(e);
            if (typeof _onChange === 'function') {
              _onChange(e);
            }
          }}
          onBlur={e => {
            if (typeof _onBlur === 'function') {
              _onBlur(e);
            } else {
              onBlur();
            }
          }}
          className={getTextInputClasses(error, inputClasses, bgColor)}
          aria-label={ariaLabel ?? id}
        />
      )}
    ></AbstractFormItem>
  );
}
